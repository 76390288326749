export const theme = {
  border: "#dfb834",
  border100: "#5C5C5C",

  text: "#000",
  background100: "#efe4d7",

  theme: "#f6e0b5",
  white: "#f1f6db",
  green: "#3d6b50",
  red: "#830909",
  gray: "#efe4d7",
  tan: "#aa6f73",
  blue: "#43594a",
} as const;
