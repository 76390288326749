import React from "react";
import {
  IoMusicalNoteOutline,
  IoPlaySkipForwardCircleOutline,
  IoPersonAddOutline,
} from "react-icons/io5";
import { Button } from "..";

import * as Styled from "./index.styled";

interface Props {
  onClose: () => void;
}

export function InfoPopUp({ onClose }: Props) {
  return (
    <Styled.Container>
      <Styled.PopUp>
        <h1>Welcome! 👋</h1>
        <Styled.Spacer />
        <Styled.Section>
          <IoMusicalNoteOutline size={70} />
          <p>
            Test your Tamil song knowledge! Listen to today&apos;s song and try
            to guess the correct title in 5 guesses.
          </p>
        </Styled.Section>
        <Styled.Section>
          <IoPlaySkipForwardCircleOutline size={70} />
          <p>
            Search with song title, music director, or movie! Incorrect guesses
            and skips will let you listen to more of the song.
          </p>
        </Styled.Section>
        <Styled.Section>
          <IoPersonAddOutline size={55} />
          <p>
            Share your Results! A new song is generated every day, so be sure to
            come back!
          </p>
        </Styled.Section>
        <Button variant="green" style={{ marginTop: 20 }} onClick={onClose}>
          Enter
        </Button>
        <Styled.Contact>
          Contact -{" "}
          <a href="mailto:tharoonbalaji@gmail.com">tharoonbalaji@gmail.com</a>{" "}
        </Styled.Contact>
      </Styled.PopUp>
    </Styled.Container>
  );
}
