import React from "react";
import { IoCloudyNightOutline, IoLogoInstagram } from "react-icons/io5";

import * as Styled from "./index.styled";

export function Footer() {
  return (
    <footer>
      <Styled.Text>
        Made by <IoCloudyNightOutline />{" "}
        <Styled.Link href="https://linktr.ee/tharoonbalaji">
          Tharoon Balaji
        </Styled.Link>
      </Styled.Text>
    </footer>
  );
}
