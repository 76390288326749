import React from "react";

import { Song } from "../../types/song";
import { GuessType } from "../../types/guess";
import { scoreToEmoji } from "../../helpers";
import "./index.css";
import { Button } from "../Button";
import { YouTube } from "../YouTube";

import * as Styled from "./index.styled";

interface Props {
  didGuess: boolean;
  currentTry: number;
  todaysSolution: Song;
  guesses: GuessType[];
}

export function Result({
  didGuess,
  todaysSolution,
  guesses,
  currentTry,
}: Props) {
  const hoursToNextDay = Math.floor(
    (new Date(new Date().setHours(24, 0, 0, 0)).getTime() -
      new Date().getTime()) /
      1000 /
      60 /
      60
  );

  const date = new Date();
  const minute = date.getMinutes();

  const leftMinute = 59 - minute;
  const textForTry = [
    "Namba Mudiyala!",
    "Vera Level!",
    "Semma!",
    "Super",
    "Amazing",
  ];
  const [text, setText] = React.useState<boolean>(false);

  if (didGuess) {
    const copyResult = React.useCallback(() => {
      navigator.clipboard.writeText(scoreToEmoji(guesses));
      setText(true);
    }, [guesses]);

    const triesConjugation = currentTry === 1 ? "try" : "tries";

    return (
      <>
        <Styled.ResultTitle>
          {" "}
          🎉{textForTry[currentTry - 1]}🎉
        </Styled.ResultTitle>
        <Styled.SongTitle>
          Today&apos;s Song is {todaysSolution.name} - {todaysSolution.artist}{" "}
          from {todaysSolution.movie}
        </Styled.SongTitle>
        <Styled.Tries>
          You guessed it in: {currentTry} {triesConjugation}
        </Styled.Tries>
        <YouTube id={todaysSolution.youtubeId} />
        <p className="sEmoji">{scoreToEmoji(guesses)}</p>
        <Button onClick={copyResult} variant="green">
          {text ? "Copied!" : "Copy Results!"}
        </Button>
        <Styled.TimeToNext>
          Thanks for playing, be sure to visit in: {hoursToNextDay} hours and{" "}
          {leftMinute} minutes!
        </Styled.TimeToNext>
      </>
    );
  } else {
    return (
      <>
        <Styled.ResultTitle>😢 Nalaikku Paappom 😢</Styled.ResultTitle>
        <Styled.SongTitle>
          {todaysSolution.name} - {todaysSolution.artist} from{" "}
          {todaysSolution.movie}
        </Styled.SongTitle>
        <YouTube id={todaysSolution.youtubeId} />
        <Styled.TimeToNext>
          Thanks for playing, be sure to visit in: {hoursToNextDay} hours and{" "}
          {leftMinute} minutes!
        </Styled.TimeToNext>
      </>
    );
  }
}
